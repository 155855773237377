<template>
  <div>
  <div class="page" ref="scrollBox" style="height:100vh;overflow-y:scroll;" @scroll="handleScroll">
    <div class="header">
      <div class="header-back" @click="backAction">
        <!-- <img
          src="https://artimg2.artart.cn/image/20220319/e2e847a9adc58d8030dd3f322483ea61.png"
          alt=""
        /> -->
      </div>
      <div class="header-title" v-if="dateList.length > 0">
        {{ dateList[ddateIndex].year }}年{{ dateList[ddateIndex].month }}月
      </div>
    </div>
    <div class="header-zw"></div>
    <div class="calendar">
      <div class="calendar-line" :style="`position: fixed;left: 0;right: 0;top: ${(60/75)}rem;z-index: 10;background: #fff;height: ${50/75}rem;`">
        <div class="calendar-column">日</div>
        <div class="calendar-column">一</div>
        <div class="calendar-column">二</div>
        <div class="calendar-column">三</div>
        <div class="calendar-column">四</div>
        <div class="calendar-column">五</div>
        <div class="calendar-column">六</div>
      </div>
      <!-- height: 500px; -->
      <div
        class="calendar-content"
        :style="`padding-top: ${(50/75)}rem;width: 10rem`"
      >
        <!-- height: 500px -->
        <van-swipe
          :show-indicators="false"
          :initial-swipe="dateIndexInit"
          @change="changeMonthAction"
          @animationfinish="editAction"
          :loop="false"
          style="width: 10rem; "
        >
          <van-swipe-item
            v-for="(item, index) in dateList"
            :key="`bid${index}`"
          >
            <div class="calendar-line">
              <div
                class="calendar-column-d"
                v-for="(m, i) in item.date"
                :key="i"
                @click="changeDateAction(m.date,m.source)"
              >
                <div
                  :class="`calendar-column-date ${
                    m.source != 'current' ? 'hui' : ''
                  }`"
                  :style="
                    m.source == 'current' &&
                    changeMonth == item.month &&
                    changeDate == m.date
                      ? 'background:' +
                        buttonBgColour +
                        ';color: ' +
                        buttonColour +
                        ';'
                      : ''
                  "
                >
                  {{ m.date }}
                  <div
                    class="sale"
                    v-if="m.source == 'current' && m.count"
                    :style="`color: ${colour};`"
                  >
                    {{ m.count }}场
                  </div>
                </div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- :style="`top: ${50 + 10 + goTop}px;`" -->
    <div
      class="calendar-content2"
      v-show="titleTop"
      :style="`top: ${(50 + 10+50)/75}rem;`"
    >
      <van-swipe
        :show-indicators="false"
        style="width: 10rem;"
        :style="`height: ${(82/75)}rem;`"
        @change="changeMonthLineAction"
        :initial-swipe="dateLineIndex" 
        @animationfinish="editAction"
        ref="swipe2"
      >
        <van-swipe-item
          v-for="(item, index) in weekData.length / 7"
          :key="'s2'+index"
        >
          <div class="calendar-line">
            <div
              class="calendar-column-d"
              v-for="(m, si) in 7"
              :key="si"
              :data-month="weekData[index * 7 + si].month"
              :data-date="weekData[index * 7 + si].date"
              @click="changeDateLineAction(weekData[index * 7 + si].month,weekData[index * 7 + si].date)"
            >
              <div
                :class="`calendar-column-date ${
                  weekData[index * 7 + si].source != 'current' ? 'hui' : ''
                }`"
                :style="
                  weekData[index * 7 + si].source == 'current' &&
                  changeMonth == weekData[index * 7 + si].month &&
                  changeDate == weekData[index * 7 + si].date
                    ? 'background:' +
                      buttonBgColour +
                      ';color: ' +
                      buttonColour +
                      ';'
                    : ''
                "
              >
                {{ weekData[index * 7 + si].date }}
                <div
                  class="sale"
                  v-if="
                    weekData[index * 7 + si].source == 'current' &&
                    weekData[index * 7 + si].count
                  "
                  :style="`color: ${colour};`"
                >
                  {{ weekData[index * 7 + si].count }}场
                </div>
              </div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div
      class="no-data"
      v-if="noAlbum"
      :style="titleTop ? 'min-height: 80vh;' : ''"
    >
      抱歉，今日暂无场次
    </div>
    <div
      class="data-show"
      v-else-if="currentDay"
      :style="titleTop ? 'min-height: 90vh;' : ''"
    >
      <div class="data-title">
        <span class="time">{{ currentYear }}{{ currentDay }}</span
        ><span class="week">{{ week }}</span
        ><span class="count" :style="`color: ${colour};`"
          >(共{{ albumCount }}场)</span
        >
      </div>
      <div class="album-data">
        <div
          class="album"
          v-for="(item, index) in albumList"
          :key="index"
          :data-id="item.album_id"
          :data-type="item.album_type"
          :data-pmListNumNew="pmListNumNew"
          @click="goalbum(item)"
          :data-showstyle="item.image_show_style"
          :data-item="item"
        >
          <div class="album-time">{{ item.sTime }}</div>
          <div class="album-r">
            <div class="album-name">
              {{ item.merchant_name }} {{ item.album_name }}
            </div>
            <div class="album-lot">
              Lot {{ item.LotArea.min }}-{{ item.LotArea.max }}
            </div>
            <div class="album-tag">
              <div
                class="tag"
                v-if="item.live_id"
                :style="`border-color: ${colour};color: ${colour};`"
              >
                直播专场
              </div>
              <div
                class="tag"
                v-if="item.fee_type != 'CNY'"
                :style="`border-color: ${colour};color: ${colour};`"
              >
                海外专场
              </div>
              <template>
                <div
                  class="tag"
                  v-if="item.bond_type == 2 && item.bond_bid_ratio"
                  :style="`border-color: ${colour};color: ${colour};`"
                >
                  保证金1:{{ item.bond_bid_ratio }}
                </div>
                <div
                  class="tag"
                  v-else-if="item.money"
                  :style="`border-color: ${colour};color: ${colour};`"
                >
                  保证金 {{ item.fee_type }}
                  {{ formatPriceNumber(item.money, 1) }}
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 120px"></div>

  </div>
  <Footer />
  </div>
</template>
<script>
import { Toast, Swipe, SwipeItem, CountDown, Switch } from "vant";
import { ref, computed, toRefs, onMounted, onActivated,nextTick } from "vue";
import { useEventListener } from "@vant/use";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { formatPriceNumber } from "@/utils/util";
import { albumListApi, getcalendar } from "@/api/auction";
export default {
  name: 'Calender',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [CountDown.name]: CountDown,
    [Switch.name]: Switch,
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    let calenderShow = ref(false);
    let titleTop = ref(false);

    const swipe2 = ref(null);
    let saveWeekIndex = 0;
    
    onMounted(() => {
      console.log(swipe2.value)
    });
    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    const tag_style = computed(() => {
      return store.getters.tag_style ? store.getters.tag_style : {};
    });
    const buttonBgColour = computed(() => {
      return store.getters.tag_style ? store.getters.tag_style.previewBtnBg : '';
    });
    const buttonColour = computed(() => {
      return store.getters.tag_style ? store.getters.tag_style.previewBtnColor : '';
    });
    const colour = computed(() => {
      return store.getters.tag_style ? store.getters.tag_style.previewActiveColor : '';
    });

    
    
    let date = [];
    let weekData = [];
    let getMonth = (month) => {
      var time = new Date();
      time.setDate(time.getDate()); //获取Day天后的日期
      var y = time.getFullYear();
      var m;
      if (time.getMonth() + month + 1 > 12) {
        y = y + 1;
        m = time.getMonth() + month - 11;
      } else if (time.getMonth() + month + 1 < 1) {
        y = y - 1;
        m = 12 + (time.getMonth() + month + 1);
      } else {
        m = time.getMonth() + month + 1;
      }
      return {
        year: y,
        month: m,
      };
    };
    for (let i = -5; i < 6; i++) {
      const d = getMonth(i);
      const monthStartDate = new Date(d.year, d.month - 1, 1); // 当月第一天
      const monthEndDate = new Date(d.year, d.month, 0); // 当月最后一天
      const startWeek = monthStartDate.getDay();
      const endWeek = monthEndDate.getDay();

      const lastMonth = getMonth(i - 1); // 上个月
      const beforeDate = new Date(lastMonth.year, lastMonth.month, 0); // 上个月最后一天
      let m = [];
      let lastCount = startWeek;
      for (let j = startWeek - 1; j >= 0; j--) {
        m = m.concat([
          {
            source: "last",
            date: beforeDate.getDate() - j,
          },
        ]);
        if (i == -5) {
          weekData = weekData.concat([
            {
              source: "last",
              date: beforeDate.getDate() - j,
            },
          ]);
        }
      }
      for (let j = 1; j <= monthEndDate.getDate(); j++) {
        m = m.concat([
          {
            source: "current",
            date: j,
          },
        ]);
        weekData = weekData.concat([
          {
            month: d.month,
            year: d.year,
            source: "current",
            date: j,
            key: `${d.year}-${d.month}-${j}`,
          },
        ]);
      }
      for (let j = 1; j < 7 - endWeek; j++) {
        m = m.concat([
          {
            source: "next",
            date: j,
          },
        ]);
        if (i == 5) {
          weekData = weekData.concat([
            {
              source: "next",
              date: j,
            },
          ]);
        }
      }
      if (m.length < 42) {
        const count = 42 - m.length;
        for (let j = 0; j < count; j++) {
          m = m.concat([
            {
              source: "next",
              date:
                1 +
                (m[m.length - 1].date == 28 ||
                m[m.length - 1].date == 29 ||
                m[m.length - 1].date == 30 ||
                m[m.length - 1].date == 31
                  ? 0
                  : m[m.length - 1].date),
            },
          ]);
        }
      }
      date = date.concat([
        {
          lastCount: lastCount,
          year: d.year,
          month: d.month,
          date: m,
        },
      ]);
    }
    const now = new Date();
    const nowDate = now.getDate();

    let dateList = ref([]);
    let changeDate = ref("");
    let changeYear = ref("");
    let changeMonth = ref("");
    let ddateIndex = ref("5");
    let dateIndexInit = ref("5");
    const weekDataKey = weekData.map((r) => r.key);
    changeDate.value = nowDate;
    let dateLineIndex = ref("");
    dateLineIndex.value = parseInt(
      weekDataKey.indexOf(
        `${date[5].year}-${date[5].month}-${changeDate.value}`
      ) / 7,
      10
    );
    dateList.value = date;
    changeYear.value = date[5].year;
    changeMonth.value = date[5].month;
    changeDate.value = nowDate;
    getcalendar().then((res) => {
      res.data.list.forEach((m) => {
        const time = m.date.split("-");
        const year = time[0];
        const month = Number(time[1]);
        const day = Number(time[2]);
        const monthList = date.map((r) => r.month);
        const monthIndex = monthList.indexOf(month);

        if (monthIndex != -1) {
          dateList.value = date[monthIndex];
          dateList.value.date[dateList.value.lastCount + day - 1].count =
            m.count;
        }
        const wdateLineIndex = weekDataKey.indexOf(`${year}-${month}-${day}`);
        if (wdateLineIndex != -1) {
          weekData[wdateLineIndex].count = m.count;
        }
        
      });
      dateList.value = date;
      setAuctionAlbumAction();
    });

    let changeData = ref({});
    let editAction = () => {
      changeMonth.value = changeData.value.changeMonth;
      changeYear.value = changeData.value.changeYear;
      if(changeData.value.changeDate){
        changeDate.value = changeData.value.changeDate;
      }
      if(changeData.value.dateLineIndex){
        dateLineIndex.value = changeData.value.dateLineIndex;
      }
      // if(changeData.value.dateIndex){
      //   dateIndex = changeData.value.dateIndex;
      // }
      changeData.value = {};
      setAuctionAlbumAction();
    };
    let changeMonthLineAction = (e) => {
      const nchangeDate = weekData[e * 7];
      const monthList = dateList.value.map((r) => r.month);
      const ndateIndex = monthList.indexOf(nchangeDate.month);
      // changeData.value = {
      //   dateIndex: ndateIndex,
      //   dateIndexInit: ndateIndex,
      //   changeMonth: nchangeDate.month,
      //   changeYear: nchangeDate.year,
      //   changeDate: nchangeDate.date,
      // };
      dateIndexInit.value = ndateIndex;
      ddateIndex.value = ndateIndex;
      changeMonth.value = nchangeDate.month;
      changeYear.value = nchangeDate.year;
      changeDate.value = nchangeDate.date;
      saveWeekIndex = e;
      // editAction();
      setAuctionAlbumAction();
    };

    let changeMonthAction = (e) => {
      ddateIndex.value = e;
      const now = new Date(
        dateList.value[ddateIndex.value].year,
        dateList.value[ddateIndex.value].month,
        0
      );
      const nowDate = now.getDate();
      if (changeDate.value > nowDate) {
        changeData.value = {
          changeMonth: dateList.value[ddateIndex.value].month,
          changeYear: dateList.value[ddateIndex.value].year,
          changeDate: nowDate,
          dateLineIndex: parseInt(
            weekDataKey.indexOf(
              `${dateList.value[ddateIndex.value].year}-${
                dateList.value[ddateIndex.value].month
              }-${nowDate}`
            ) / 7,
            10
          ),
        };
      } else {
        changeData.value = {
          changeMonth: dateList.value[ddateIndex.value].month,
          changeYear: dateList.value[ddateIndex.value].year,
          dateLineIndex: parseInt(
            weekDataKey.indexOf(
              `${dateList.value[ddateIndex.value].year}-${
                dateList.value[ddateIndex.value].month
              }-${changeDate.value}`
            ) / 7,
            10
          ),
        };
      }
      editAction();
    };
    let changeDateLineAction = (month, date) => {
      const monthList = dateList.value.map((r) => r.month);
      const dateIndex = monthList.indexOf(month);
      ddateIndex.value = dateIndex;
      dateIndexInit.value = dateIndex;
      changeMonth.value = month;
      changeDate.value = date;
      setAuctionAlbumAction();
    };
    let changeDateAction = (da,so) => {
      const date = da;
      const source = so;
      if (source == "last" && ddateIndex.value > 0) {
        ddateIndex.value = ddateIndex.value - 1;
        dateIndexInit.value = ddateIndex.value - 1;
        changeDate.value = date;
      } else if (
        source == "next" &&
        ddateIndex.value < dateList.value.length - 1
      ) {
        ddateIndex.value = ddateIndex.value + 1;
        dateIndexInit.value = ddateIndex.value + 1;
        changeDate.value = date;
      } else if (source == "current") {
        changeDate.value = date;
      }
      dateLineIndex.value = parseInt(
        weekDataKey.indexOf(
          `${dateList.value[ddateIndex.value].year}-${
            dateList.value[ddateIndex.value].month
          }-${changeDate.value}`
        ) / 7,
        10
      );
      setAuctionAlbumAction();
    };
    let noAlbum = ref(false);
    let albumCount = ref(0);
    let currentYear = ref("");
    let currentDay = ref("");
    let page = ref("1");
    let week = "";
    let setAuctionAlbumAction = () => {
      console.log(ddateIndex.value);
      const dateIndex =
        dateList.value[ddateIndex.value].lastCount + changeDate.value - 1;
      const date = dateList.value[ddateIndex.value].date[dateIndex];
      if (date.count > 0) {
        const weeks = parseInt(dateIndex % 7, 10);
        switch (weeks) {
          case 0:
            week = "星期日";
            break;
          case 1:
            week = "星期一";
            break;
          case 2:
            week = "星期二";
            break;
          case 3:
            week = "星期三";
            break;
          case 4:
            week = "星期四";
            break;
          case 5:
            week = "星期五";
            break;
          case 6:
            week = "星期六";
            break;
        }
        const now = new Date();
        const currYear = now.getFullYear();
        noAlbum.value = false;
        albumCount.value = date.count;
        currentYear.value =
          currYear == changeYear.value ? "" : changeYear.value + "年";
        currentDay.value = `${changeMonth.value}月${changeDate.value}日`;
        page.value = 1;
        albumList.value = [];
        loadAlbumAction();
      } else {
        noAlbum.value = true;
        albumCount.value = 0;
        week = "";
        currentYear.value = "";
        currentDay.value = "";
      }
    };
    let hasMore = ref(false);
    let isLoading = ref(false);
    let albumList = ref([]);
    let loadAlbumAction = () => {
      albumListApi({
        day: `${changeYear.value}-${changeMonth.value}-${changeDate.value}`,
        page: page.value,
        limit: 20,
        status: 3,
      }).then((res) => {
        if (res.status == 200) {
          res.data.list.forEach((m) => {
            m.sTime = m.stime_format[0];
          });
          albumList.value = albumList.value.concat(res.data.list);
        }
        if (
          albumList.value.length > 0 &&
          albumList.value.length < res.data.total
        ) {
          hasMore.value = true;
        }
        isLoading.value = false;
      });
    };
    let goalbum = (item) => {
      router.push({
        path: "/pages/albumDetail/index",
        query: { id: item.album_id },
      });
    }
    let scrollTop = ref(0);
    const scrollBox = ref(null);
    const handleScroll = () => {
      scrollTop.value = scrollBox.value.scrollTop;
      if (scrollTop.value > 450 && !calenderShow.value) {
        calenderShow.value = true;
        titleTop.value = true;
        saveWeekIndex = dateLineIndex.value;
        swipe2.value.resize();
        // swipe2.value.swipeTo(dateLineIndex);
      } else if (scrollTop.value < 80 && calenderShow.value) {
        calenderShow.value = false;
        titleTop.value = false;
        dateLineIndex.value = saveWeekIndex;
      }
    }
    onActivated(() => {
      if(scrollTop.value > 0){
        setTimeout(() => {
          nextTick(() => {
            scrollBox.value.scrollTop = scrollTop.value;
          })
        }, 10)
      }
    })
    // useEventListener("scroll", () => {
    //   let scrollTop =
    //     window.scrollY ||
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   if (scrollTop > 450 && !calenderShow.value) {
    //     calenderShow.value = true;
    //     titleTop.value = true;
    //     saveWeekIndex = dateLineIndex.value;
    //     swipe2.value.resize();
    //     // swipe2.value.swipeTo(dateLineIndex);
    //   } else if (scrollTop < 80 && calenderShow.value) {
    //     calenderShow.value = false;
    //     titleTop.value = false;
    //     dateLineIndex.value = saveWeekIndex;
    //   }
    // });
    return {
      backAction,
      formatPriceNumber,
      tag_style,
      buttonBgColour,
      buttonColour,
      colour,
      changeDate,

      dateList,
      changeMonth,
      changeYear,
      dateLineIndex,
      weekData,
      weekDataKey,
      titleTop,

      albumList,
      isLoading,
      hasMore,

      editAction,
      changeMonthLineAction,
      ddateIndex,

      changeMonthAction,
      changeDateLineAction,
      changeDateAction,

      noAlbum,
      albumCount,
      currentYear,
      currentDay,
      page,
      week,
      dateIndexInit,
      pmListNumNew: 2,

      swipe2,
      goalbum,

      handleScroll,
      scrollTop,
      scrollBox
    };
  },
};
</script>
<style scoped>
.page {
  background: #f4f4f4;
  min-height: 100vh;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 49px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  z-index: 10000;
  background: #fff;
  padding-bottom: 10px;
  overflow: hidden;
}

.header-back {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-back .img {
  width: 14px;
  height: 14px;
  display: block;
}
.header-zw {
  height: 49px;
}
.header-title {
  text-align: center;
  flex: 1;
  font-size: 30px;
  overflow: hidden;
  /*超出部分隐藏*/
  white-space: nowrap;
  /*不换行*/
  text-overflow: ellipsis;
  /*超出部分文字以...显示*/
  padding-right: 48px;
  font-weight: 600;
}

.calendar {
  border-top: 1px solid #ededed;
  background: #fff;
}
.calendar-line {
  width: 750px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
}

.calendar-column {
  width: 14.2vw;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  padding-top: 9px;
}

.calendar-column-d {
  width: 14.2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 17px;
}

.calendar-column-date {
  width: 65px;
  height: 58px;
  text-align: center;
  padding-top: 7px;
  font-size: 22px;
  position: relative;
}

.calendar-column-date.hui {
  color: #989898;
}

.curr .calendar-column-date {
  background: #b8202c;
  border-radius: 4px;
  color: #fff;
}

.calendar-column-date .sale {
  width: 53px;
  height: 23px;
  position: absolute;
  bottom: 8px;
  border-radius: 2px;
  left: 6px;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  background: #fff;
  color: #b8202c;
}

.no-data {
  margin-top: 120px;
  text-align: center;
  font-size: 30px;
  color: #999;
  background: none;
}

.data-show {
  padding: 30px 30px 0 30px;
}

.data-title {
  font-size: 24px;
  font-weight: 600;
}

.data-title .week {
  margin-left: 10px;
  color: #6a6a6a;
}

.data-title .count {
  margin-left: 10px;
}

.album {
  width: 690px;
  height: 230px;
  margin-top: 30px;
  background: #fff;
  display: flex;
  align-items: center;
}

.album-time {
  text-align: center;
  flex: 1;
  margin-right: 30px;
  font-weight: 600;
  color: #6a6a6a;
  font-size: 44px;
}

.album-r {
  width: 400px;
  margin-right: 39px;
}

.album-name {
  font-size: 30px;
  font-weight: 600;
  line-height: 45px;
  max-height: 90px;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.album-lot {
  font-size: 24px;
  color: #989898;
  margin-top: 10px;
}
.album-tag {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.album-tag .tag {
  margin-top: 10px;
  margin-right: 10px;
  padding: 0 7px;
  line-height: 25px;
  height: 25px;
  font-size: 18px;
  border: 2px solid #fff;
  border-radius: 2px;
}

.calendar-content {
  position: relative;
}
.calendar-content2 {
  position: fixed;
  width: 750px;
  height: 82px;
  left: 0;
  top: 0;
  z-index: 1;
  background: #fff;
}
</style>
